<template>
  <div>
    <b-form @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('modals.createPersonalizationSet.name') + '*'"
          >
            <b-form-input
              type="text"
              debounce="500"
              v-model.trim="$v.stepOneData.setName.$model"
              :state="validateState('setName')"
              :disabled="isReimport"
              autocomplete="name"
              autofocus
            ></b-form-input>
            <b-form-invalid-feedback>
              <div class="error" v-if="!$v.stepOneData.setName.required">
                {{ $t('modals.createPersonalizationSet.microsoft.errors.required') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('modals.createPersonalizationSet.description')"
          >
            <b-form-input
              type="text"
              debounce="500"
              :disabled="isReimport"
              v-model.trim="$v.stepOneData.setDescription.$model"
              :state="validateState('setDescription')"
              autocomplete="setDescription"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('modals.createPersonalizationSet.microsoft.clientId') + '*'"
          >
            <b-form-input
              type="text"
              debounce="500"
              disabled
              v-model.trim="$v.stepOneData.clientId.$model"
              :state="validateState('clientId')"
              autocomplete="client-id"
            ></b-form-input>
            <b-form-invalid-feedback>
              <div class="error" v-if="!$v.stepOneData.clientId.required">
                {{ $t('modals.createPersonalizationSet.microsoft.errors.required') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('modals.createPersonalizationSet.microsoft.secret') + '*'"
          >
            <b-form-input
              type="text"
              debounce="500"
              disabled
              v-model.trim="$v.stepOneData.secret.$model"
              :state="validateState('secret')"
              autocomplete="secret"
            ></b-form-input>
            <b-form-invalid-feedback>
              <div class="error" v-if="!$v.stepOneData.secret.required">
                {{ $t('modals.createPersonalizationSet.microsoft.errors.required') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('modals.createPersonalizationSet.microsoft.authorityUrl') + '*'"
          >
            <b-form-input
              type="text"
              debounce="500"
              disabled
              v-model.trim="$v.stepOneData.authorityUrl.$model"
              :state="validateState('authorityUrl')"
              autocomplete="client-id"
            ></b-form-input>
            <b-form-invalid-feedback>
              <div class="error" v-if="!$v.stepOneData.authorityUrl.required">
                {{ $t('modals.createPersonalizationSet.microsoft.errors.required') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('modals.createPersonalizationSet.microsoft.serverUrl') + '*'"
          >
            <b-form-input
              type="text"
              debounce="500"
              disabled
              v-model.trim="$v.stepOneData.serverUrl.$model"
              :state="validateState('serverUrl')"
              autocomplete="client-id"
            ></b-form-input>
            <b-form-invalid-feedback>
              <div class="error" v-if="!$v.stepOneData.serverUrl.required">
                {{ $t('modals.createPersonalizationSet.microsoft.errors.required') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="auto" class="mr-auto">
        </b-col>
        <b-col cols="auto">
          <b-button
            size="lg"
            type="submit"
            class="green-button border-0 mt-2"
            :disabled="$v.$invalid"
          >
            {{ $t('modals.createPersonalizationSet.next') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'PersonalizationMicrosoftStepOne',
  mixins: [validationMixin],
  props: {
    microsoftData: {
      type: Object,
      required: true,
    },
    isReimport: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    stepOneData: {
      setName: '',
      setDescription: '',
      clientId: '',
      secret: '',
      authorityUrl: '',
      serverUrl: '',
    },
  }),
  watch: {
    microsoftData: {
      handler() {
        this.stepOneData = {
          setName: this.microsoftData.setName,
          setDescription: this.microsoftData.setDescription,
          clientId: this.microsoftData.clientId,
          secret: this.microsoftData.secret,
          authorityUrl: this.microsoftData.authorityUrl,
          serverUrl: this.microsoftData.serverUrl,
        };
      },
      immediate: true,
    },
  },
  validations: {
    stepOneData: {
      setName: {
        required,
      },
      setDescription: {},
      clientId: {
        required,
      },
      secret: {
        required,
      },
      authorityUrl: {
        required,
      },
      serverUrl: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.stepOneData[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      console.log('onSubmit', this.$v.$anyError);
      if (this.$v.$anyError) {
        return;
      }
      this.$emit('next-step', this.stepOneData);
    },
  },
};
</script>

<style scoped lang="scss">
.modal-body h4 {
  font-size: 15px;
  font-weight: bold;
  color: #44484C;
}

ul {
  padding-left: 0;
  list-style: none;

  li {
    color: #44484C;

    /deep/ svg {
      color: #B9B9B9;

      &.active {
        color: #53DC86;
      }
    }

    &.past-step {
      cursor: pointer;
    }
  }
}

div.step-1 {
  min-height: 120px;
}

.grey-line {
  height: 30px;
  border-left: 1px solid #B9B9B9;
  margin-left: 6px;
}

.show-url-icon svg {
  font-size: 60px;
}

.show-url {
  font-size: 1.1rem;
}

.loading-spinner {
  min-height: 400px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-spinner {
  position: absolute;
  inset: 0px;
  opacity: 0.85;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
}
.disabled {
  cursor: not-allowed;
}
</style>
